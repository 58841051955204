import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { SkipNavContent } from "@reach/skip-nav";
import Head from "next/head";

import Footer from "@landing-components/Footer/Footer";
import NavBar from "@landing-components/NavBar/NavBar";
import Button from "@components/Button/Button";
import Input, { emailValidator } from "@components/Input/Input";
import ScribeEditor from "@components/ScribeEditor/ScribeEditor";
import SEO from "@components/seo";

import TabletPreview from "@components/TabletPreview/TabletPreview";

import { useScrollTopThreshold, useScrollPercentage } from "@hooks/window-hooks";
import * as gtag from "@modules/gtag";

import "./index.scss";

const LandingPage = () => {
    const initialValue = [
        {
            type: "paragraph",
            children: [
                {
                    text:
                        "Knowledge hubs have become a source of misery for many - often full of outdated and incomplete information. We think they can be so much more.",
                },
            ],
        },
        {
            type: "paragraph",
            children: [
                {
                    text: "Scribe is a tool for ",
                },
                {
                    text: "asynchronous communication",
                    backgroundColor: "blue",
                },
                {
                    text:
                        " that lets you take a thought from its inception all the way to content that you can share and collaborate on with your team.",
                },
            ],
        },
        {
            type: "paragraph",
            children: [
                {
                    text:
                        "It puts an emphasis on forming relationships between content as opposed to static folder hierarchies. It lets you ingest and connect content from other sources so your team can continue using their tools of choice (before we slowly win them over). Dare I say it's even fun to use!",
                },
            ],
        },
        {
            type: "paragraph",
            children: [
                {
                    text: "Of course that doesn't mean we have forgotten the editor. ",
                },
                {
                    text: "In fact you're using it right now.",
                    bold: true,
                },
                {
                    text: " Try and edit some text!",
                },
            ],
        },
    ];

    const landingPageRef = useRef();
    const isAtTop = useScrollTopThreshold(40);
    const [showNavBar, setShowNavBar] = useState(true);
    const [navBarMode, setNavBarMode] = useState("all");
    const [hasRequestedAccess, setHasRequestedAccess] = useState(false);
    const [submittingBetaRequest, setSubmittingBetaRequest] = useState(false);
    const [errorMessage, setErrorMessage] = useState({ main: "", sub: "" });
    useScrollPercentage(
        90,
        () => {
            gtag.event({
                action: "scroll",
                category: "Landing Page",
                label: "scrolled to bottom",
            });
        },
        true
    );

    const router = useRouter();

    useEffect(() => {
        const focusEmailInput = router.asPath.includes("request-access");

        if (focusEmailInput) document.querySelector("#request-access-email").focus();
    }, []);

    useEffect(() => {
        setShowNavBar(isAtTop);
    }, [isAtTop]);

    useEffect(() => {
        function onIntersect(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("in-view");
                } else {
                    entry.target.classList.remove("in-view");
                }
            });
        }

        // We want to observe when the chips are inside their container which has a bounded height
        function initObserver() {
            //const rootEl = window;
            const shapesToObserve = document.querySelectorAll("[data-shape]");
            const observer = new IntersectionObserver(onIntersect, { threshold: 0.2 });

            for (let i in shapesToObserve) {
                if (!shapesToObserve.hasOwnProperty(i)) continue;
                observer.observe(shapesToObserve[i]);
            }

            return () => {
                for (let i in shapesToObserve) {
                    if (!shapesToObserve.hasOwnProperty(i)) continue;
                    observer.unobserve(shapesToObserve[i]);
                }
            };
        }

        if (window.IntersectionObserver) {
            return initObserver();
        }
    }, []);

    useEffect(() => {
        function onIntersect(entries) {
            const passedHeroSection = !entries[0].isIntersecting;
            const navBarMode = passedHeroSection ? "access" : "all";

            setNavBarMode(navBarMode);
            setShowNavBar(isAtTop || passedHeroSection);
        }

        function initObserver() {
            const heroEl = document.querySelector(".hero");
            const observer = new IntersectionObserver(onIntersect, { threshold: 0.01 });

            observer.observe(heroEl);

            return () => observer.unobserve(heroEl);
        }

        if (window.IntersectionObserver) {
            return initObserver();
        }
    }, [isAtTop]);

    const [editorValue, setEditorValue] = useState(initialValue);
    const [email, setEmail] = useState();

    function removeCursor() {
        const newEditorValue = editorValue.map((value) => {
            if (value.children[0].cursor) {
                return { ...value, children: [{ ...value.children[0], cursor: false }] };
            } else return value;
        });

        //setEditorValue(newEditorValue);
    }

    async function handleRequestAccessClick(e) {
        e.preventDefault();
        if (!email) return;

        setSubmittingBetaRequest(true);

        gtag.event({
            action: "submit_form",
            category: "Landing Page",
            label: "request beta access",
        });

        const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/beta-access`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
        });

        if (response.status === 409) {
            setErrorMessage({
                main: "Access already requested",
                sub: "I respect how keen you are. Expect an email very soon!",
            });
        }

        setHasRequestedAccess(true);
        setSubmittingBetaRequest(false);
    }

    const tablet = {
        id: 1,
        collectionID: 9,
        collectionName: "GIT COMMIT MESSAGES",
        title: "Meeting Notes - 02/02/20",
        createdAt: new Date(),
        author: "Francesco Lanciana",
        stats: { count: 14 },
    };

    function handleBottomRequestAccess(e) {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        setTimeout(() => document.querySelector("#request-access-email").focus(), 1000);
    }

    return (
        <div className="landing-page" ref={landingPageRef}>
            <Head>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                            if (document.cookie && document.cookie.indexOf('workspace=') > -1) {
                                const match = document.cookie.match(new RegExp('(^| )workspace=([^;]+)'));
                                if (match && match[2]) {
                                    window.location.href = "/" + match[2] + "/recent";
                                }
                            }
                        `,
                    }}
                />
            </Head>
            <SEO type="corporation" metadata={{ title: "Scribe - A Second Brain for you and your team" }} />
            <NavBar show={showNavBar} mode={navBarMode} />

            <main>
                <SkipNavContent />
                <section className="hero">
                    <div className="container hero-content">
                        <div className="title-signup">
                            <h1 className="main-title">A Second Brain for You and Your Team</h1>

                            <form
                                className="signup-form"
                                onSubmit={handleRequestAccessClick}
                                data-requested-access={hasRequestedAccess}
                            >
                                <div className="input-container">
                                    <Input
                                        label="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        type="email"
                                        id="request-access-email"
                                    />
                                </div>
                                <div className="button-container">
                                    <Button variant="primary" size="medium" loading={submittingBetaRequest}>
                                        Request Access
                                    </Button>
                                </div>

                                <div className="thank-you">
                                    <p>{errorMessage.main || "Thank you"}</p>
                                    <p>{errorMessage.sub || "Expect an email very soon"}</p>
                                </div>
                            </form>
                        </div>

                        <div className="hero-image-container">
                            <img src="/static/page-page.jpg" className="hero-image" alt="Scribe" />
                        </div>
                    </div>

                    <div className="background-shape">
                        <svg
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 4096 730"
                            preserveAspectRatio="xMaxYMid meet"
                        >
                            <title>landing-page-background-large</title>
                            <polygon className="cls-1" points="4098 730 0 730 0 0 4097 2 4098 730" />
                            <path
                                className="cls-2"
                                d="M2352,365c-154-174-215-204.36-343-232-88-19-139-28-177-133,732.33.33,1531.17.17,2263.5.5-.33,230,1.83,498.5,1.5,728.5-262-18-280.53-123.16-535-114-361,13-357-190-597-148C2829.06,490.79,2513.39,547.35,2352,365Z"
                            />
                        </svg>
                    </div>
                </section>

                <section className="pitch section">
                    <div className="container small vertical">
                        <h2 className="title">Reimagining the wiki</h2>

                        <ScribeEditor
                            editMode={true}
                            withAuthor={false}
                            autoFocus={false}
                            value={editorValue}
                            playgroundMode
                            onChange={(state) => {
                                gtag.event({
                                    action: "key_press",
                                    category: "Landing Page",
                                    label: "use editor",
                                });
                                setEditorValue(state);
                            }}
                            onFocus={removeCursor}
                        />

                        <div className="seperator-container">
                            <div className="seperator">•</div>
                        </div>
                    </div>
                </section>

                <div className="features-section">
                    {/* Hidden anchor so I can control the landing position */}
                    <a id="features" name="feature section"></a>
                    <section className="trigger-section section feature-section container">
                        <div className="feature-container">
                            <div className="feature-img-container">
                                <img
                                    src="/static/collections-page.jpg"
                                    className="feature-image"
                                    alt="No more hierarchy"
                                    loading="lazy"
                                />
                            </div>
                            <div className="feature-text-container">
                                <h3>No more Hierarchy</h3>

                                <p>
                                    A folder structure is hard to maintain at the best of times. Scribe
                                    removes this dated heirarchical structure and focuses on forming
                                    relationships between content. Build a web of knowledge that lets you
                                    connect anything and everything. For when you need to group content
                                    collections have you covered. We promise folders will look silly after
                                    trying Scribe.
                                </p>
                            </div>
                        </div>
                        <div className="shapes-container">
                            <div className="shape circle shape-circle-1" data-shape></div>
                        </div>
                    </section>
                    <section className="tablets-section section feature-section container">
                        <div className="feature-container">
                            <div className="feature-img-container">
                                <img
                                    src="/static/profile-page.jpg"
                                    className="feature-image"
                                    alt="Built for individuals and teams"
                                    loading="lazy"
                                />
                                {/* <TabletPreview {...tablet} status="outdated" contained /> */}
                            </div>
                            <div className="feature-text-container">
                                <h3>Built for individuals and teams</h3>

                                <p>
                                    Every piece of content you write in Scribe is private until shared. We
                                    make it simple to capture your thoughts, synthesise those thoughts into a
                                    curated piece of content to share, and even get feedback from team members
                                    before sharing. Once shared we make it easy for others to ask questions
                                    and have the answers folded back into the page.
                                </p>
                            </div>
                        </div>
                        <div className="shapes-container">
                            <div className="shape circle small shape-circle-2" data-shape></div>
                        </div>
                    </section>
                    <section className="tablets-section section feature-section container">
                        <div className="feature-container">
                            <div className="feature-img-container">
                                <img
                                    src="/static/blank-page.jpg"
                                    className="feature-image blank"
                                    alt="A knowledge aggregator"
                                    loading="lazy"
                                />
                                <div className="coming-soon-text">Coming Soon</div>
                            </div>
                            <div className="feature-text-container">
                                <h3>A knowledge aggregator</h3>

                                <p>
                                    We understand that getting team-wide adoption for any tool is hard. This
                                    is where the web of knowledge really shines. We let you ingest, sync and
                                    connect content from other sources. Some team members use Confluence - no
                                    problem. Others enjoy note taking in Evernote - great! Think of Scribe as
                                    a layer that sits across and connects all your knowledge sources.
                                </p>
                            </div>
                        </div>
                        <div className="shapes-container">
                            <div className="shape circle small shape-circle-2" data-shape></div>
                        </div>
                    </section>
                    <section className="tablets-section section feature-section container">
                        <div className="feature-container">
                            <div className="feature-img-container">
                                <img
                                    src="/static/blank-page.jpg"
                                    className="feature-image blank"
                                    alt="Built for individuals and teams"
                                    loading="lazy"
                                />
                                <div className="coming-soon-text">Coming Soon</div>
                                {/* <TabletPreview {...tablet} status="outdated" contained /> */}
                            </div>
                            <div className="feature-text-container">
                                <h3>Team Accountability</h3>

                                <p>
                                    When you share a piece of knowledge you and your direct team now own that
                                    content. If anyone asks a question it will be placed in every owners task
                                    list until answered. No more relying on a noisy notification system.
                                </p>
                            </div>
                        </div>
                        <div className="shapes-container">
                            <div className="shape circle small shape-circle-2" data-shape></div>
                        </div>
                    </section>
                    <section className="wrapping-up">
                        <p>
                            Scribes mission is to massively increase the worlds collective productivity.
                            Knowledge hubs shouldn't be a source of misery. They should be a tool for
                            asynchronous communication that keep individuals productive and teams aligned. If
                            this excites you sign up for beta access - we would love to have you along for the
                            journey.
                        </p>
                        <div className="request-access-btn-container">
                            <Button size="small" variant="primary" onClick={handleBottomRequestAccess}>
                                Request Beta Access
                            </Button>
                        </div>
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default LandingPage;
